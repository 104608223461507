import { styled } from '@mui/system'
import { ChangeCountryLanguage } from '../../change-country-language'

const BottomAreaWrapper = styled('div')`
	background-color: ${({ theme }) => theme.palette.footer.bgBottom};
	display: flex;
	align-items: center;
	border-bottom: 1px solid ${({ theme }) => theme.palette.footer.border};
	justify-content: center;
	text-align: left;
	padding: 0;
	flex-direction: column;
	a {
		color: ${({ theme }) => theme.palette.footer.color};
	}
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: 0 var(--spacing-2);
		justify-content: flex-start;
		flex-direction: row;
		text-align: center;
		border-top: 1px solid ${({ theme }) => theme.palette.footer.border};
		align-items: stretch;
	}

	& .footer-storelocator-link {
		width: 100%;
		justify-content: flex-start;
		padding: var(--spacing-3) var(--spacing-1);
		border-radius: 0;
		text-transform: lowercase;
		min-height: 0;
		font-family: var(--font-base-regular);
		border-bottom: 1px solid ${({ theme }) => theme.palette.footer.border};
		white-space: nowrap;
		${({ theme }) => theme.breakpoints.up('md')} {
			width: auto;
			justify-content: center;
			border-bottom: none;
			padding: 20px var(--spacing-2) 20px 0;
			border-right: 1px solid ${({ theme }) => theme.palette.footer.border};
			&:hover,
			&:active {
				border-bottom: none;
				border-right: 1px solid ${({ theme }) => theme.palette.footer.border};
			}
		}
	}
`

const ChangeCountryLanguageStyled = styled(ChangeCountryLanguage)`
	&.change-country-language-trigger-footer {
		flex-grow: 1;
		justify-content: flex-start;
		width: 100%;
		padding: var(--spacing-3) var(--spacing-1);
		border-bottom: 1px solid ${({ theme }) => theme.palette.footer.border};
		${({ theme }) => theme.breakpoints.up('md')} {
			flex-grow: 0;
			width: auto;
			border-bottom: none;
			padding: 20px var(--spacing-2);
			justify-content: center;
			border-right: 1px solid ${({ theme }) => theme.palette.footer.border};
		}
	}
`

const CopyrightWrapper = styled('div')`
	padding: var(--spacing-2) var(--spacing-1) var(--spacing-4);

	${({ theme }) => theme.breakpoints.up('md')} {
		padding: 0 var(--spacing-2);
		margin-left: auto;
		text-align: right;
		align-self: center;
	}

	p {
		${({ theme }) => theme.typography.caption}
		line-height: 2 !important;
		font-size: 11px !important;
	}
`

export { BottomAreaWrapper, ChangeCountryLanguageStyled, CopyrightWrapper }
