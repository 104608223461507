import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { cx } from '@emotion/css'
import { useTheme } from '@mui/system'
import { object } from 'prop-types'
import Typography from '../../../atoms/typography'
import RichContent from '../../../particles/rich-content'
import { ChangeCountryLanguageStyled, BottomAreaWrapper, CopyrightWrapper } from './bottom-area.styled'
import { FormattedMessage } from 'react-intl'
import MapPinIcon from '../../../atoms/icons/map-pin-icon'
import StorelocatorLink from '../storelocator-link'

const BottomArea = ({ copyrightProps, className }) => {
	const { copyright } = useConfig()
	const theme = useTheme()

	return (
		<BottomAreaWrapper className={cx('BottomArea-root', className)}>
			<StorelocatorLink
				className="footer-storelocator-link"
				buttonProps={{
					StartIcon: MapPinIcon,
					variant: 'ghost',
					iconProps: { fontSize: 'middle' },
					label: <FormattedMessage id={'footer_store_locator_link_label'} />,
				}}
			/>
			<ChangeCountryLanguageStyled showLabel={true} className="change-country-language-trigger-footer" />
			<CopyrightWrapper>
				<Typography
					sx={{
						color: theme.palette.footer.color,
					}}
					component="div"
					{...copyrightProps}
				>
					<RichContent content={copyright} />
				</Typography>
			</CopyrightWrapper>
		</BottomAreaWrapper>
	)
}

BottomArea.propTypes = {
	/**
	 * Defines the properties of copyright text
	 */
	copyrightProps: object,
}

export default BottomArea
