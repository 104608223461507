import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { useTheme } from '@mui/system'
import { cx } from '@emotion/css'
import { array, object } from 'prop-types'
import ArrowheadRightIcon from '../../../atoms/icons/arrowhead-right-icon'
import Newsletter from '../../newsletter'
import Menus from '../menus'
import { MiddleTopWrapper, NewsletterWrapper } from './middle-top-area.styled'
import { useIntl } from 'react-intl'
import SocialLinks from '../social-links'

const MiddleTopArea = ({ menus, newsletterProps, className }) => {
	const { formatMessage } = useIntl()
	const { newsletter } = useConfig()
	const theme = useTheme()

	return menus.length > 0 || newsletter.enabled ? (
		<MiddleTopWrapper className={cx('MiddleTopArea-root', className)}>
			{menus.length > 0 && <Menus className="BHFooterMenus" menus={menus} />}

			{newsletter.enabled && (
				<NewsletterWrapper>
					<Newsletter
						className="BHNewsletter"
						source="Footer"
						variant="compact"
						action="dialog"
						title={formatMessage({ id: 'form_newsletter_title' })}
						titleProps={{
							variant: 'h4',
							sx: {
								color: theme.palette.footer.color,
							},
						}}
						description={formatMessage({ id: 'form_newsletter_subtitle' })}
						descriptionProps={{ variant: 'body2', component: 'p' }}
						iconButtonProps={{
							variant: 'icon',
							color: 'primary',
							Icon: ArrowheadRightIcon,
						}}
						checkboxProps={{
							color: 'secondary',
							labelProps: {
								color: theme.palette.primary.contrastText,
								sx: {
									alignItems: 'flex-start',
									marginTop: '24px',
									textAlign: 'left',
								},
							},
						}}
						inputProps={{
							variant: 'filled',
							color: 'white',
						}}
						{...newsletterProps}
					/>
				</NewsletterWrapper>
			)}
			<SocialLinks
				buttonProps={{ component: 'a', variant: 'icon', color: 'primary' }}
				iconProps={{ fontSize: 'middle' }}
				className="BHFooterSocialLinks"
			/>
		</MiddleTopWrapper>
	) : null
}

MiddleTopArea.propTypes = {
	/**
	 * Defines the footer menus array
	 */
	menus: array,
	/**
	 * Defines newsletter properties
	 */
	newsletterProps: object,
}

export default MiddleTopArea
