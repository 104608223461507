import { styled } from '@mui/system'
import { ChangeCountryLanguage } from '../../change-country-language'

const MiddleBottomWrapper = styled('div')`
	flex-wrap: wrap;
	padding: 0;
	background-color: ${({ theme }) => theme.palette.footer.bgMiddleBottom};
	color: ${({ theme }) => theme.palette.footer.color};
	display: flex;
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: var(--spacing-5) var(--spacing-2);
	}
	& .BHFooterSocialLinks {
		display: none;
		${({ theme }) => theme.breakpoints.up('md')} {
			width: auto;
			display: block;
			justify-content: flex-start;
			width: 100%;
			flex-grow: 1;
			display: flex;
			align-items: center;
			padding: 0;
		}
		& .BHSocialWrapper {
			& a {
				margin: 0;
				padding: 0 var(--spacing-2) 0 0;
				color: var(--color-primary);
			}
		}
	}
`

const ChangeCountryLanguageStyled = styled(ChangeCountryLanguage)`
	&.change-country-language-trigger-footer {
		flex-grow: 1;
		justify-content: center;
		border-bottom: 1px solid ${({ theme }) => theme.palette.footer.border};
		border-right: 1px solid ${({ theme }) => theme.palette.footer.border};
		padding: 30px 0;
		${({ theme }) => theme.breakpoints.up('md')} {
			flex-grow: 0;
			margin-right: 60px;
			width: auto;
			border-bottom: none;
			border-right: none;
			padding: 0;
		}
	}
`

export { ChangeCountryLanguageStyled, MiddleBottomWrapper }
