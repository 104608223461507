import { styled } from '@mui/system'

const MiddleTopWrapper = styled('div')`
	background-color: ${({ theme }) => theme.palette.footer.bgMiddleTop};
	border-top: 1px solid ${({ theme }) => theme.palette.footer.border};
	display: flex;
	flex-direction: column;
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
		padding: var(--spacing-4) var(--spacing-2);
	}
	& .BHFooterMenus {
		padding: 0;
		order: 3;
		${({ theme }) => theme.breakpoints.up('md')} {
			order: 0;
		}
		& .BhFooterMenuTitle {
			color: ${({ theme }) => theme.palette.footer.color};
		}
		& .MuiPaper-root {
			border: none;
			& .MuiAccordionSummary-content {
				color: ${({ theme }) => theme.palette.footer.color};
			}
		}
	}
	& .BHNewsletter {
		padding: var(--spacing-8) var(--spacing-1) var(--spacing-3);

		${({ theme }) => theme.breakpoints.up('md')} {
			padding: 0;
		}
	}
	& .BHFooterSocialLinks {
		width: 100%;
		flex-grow: 1;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: var(--spacing-3) var(--spacing-1) var(--spacing-5);
		order: 2;
		${({ theme }) => theme.breakpoints.up('md')} {
			display: none;
		}
		& .BHSocialWrapper {
			& a {
				margin: 0;
				padding: 0 var(--spacing-2) 0 0;
				color: var(--color-primary);
			}
		}
	}
`

const NewsletterWrapper = styled('div')`
	max-width: 100%;
	order: 1;
	${({ theme }) => theme.breakpoints.up('md')} {
		order: 2;
	}
`

export { MiddleTopWrapper, NewsletterWrapper }
