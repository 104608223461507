import { cx } from '@emotion/css'
import SocialLinks from '../social-links'
import { MiddleBottomWrapper } from './middle-bottom-area.styled'

const MiddleBottomArea = (className) => {
	return (
		<MiddleBottomWrapper className={cx('MiddleBottomArea-root', className)}>
			<SocialLinks
				buttonProps={{ component: 'a', variant: 'icon', color: 'primary' }}
				iconProps={{ fontSize: 'middle' }}
				className="BHFooterSocialLinks"
			/>
		</MiddleBottomWrapper>
	)
}

export default MiddleBottomArea
